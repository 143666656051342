<template>
  <section :class="['section-title', rootClasses]">
    <div v-if="image" class="section-title__icon">
      <UiImage :src="image" :width="changeXL(64, 40)" :height="changeXL(64, 40)" loading="lazy" />
    </div>
    <div class="section-title__text">{{ text }}</div>
    <slot name="append" />
  </section>
</template>

<script setup lang="ts">
import type { ISectionTitleProps } from './SectionTitle.types';
import type { TBackgroundColor } from '~/types/Shared.types';
import { Colors } from '~/constants/colors.constants';

const props = withDefaults(defineProps<ISectionTitleProps>(), {
  type: 'default',
});

const changeXL = GlobalUtils.Media.changeByMedia('xl');

const rootClasses = computed(() => ({
  'section-title--offer': props.type === 'offer',
}));

const styleVariables = computed(() => {
  const lineColor: TBackgroundColor = props.lineColor
    ? props.lineColor
    : {
        colorStops: ['var(--main-color-100)', Colors.ADDITIONAL.TRANSPARENT],
        toDirection: '270deg',
      };

  const textColor: TBackgroundColor = props.textColor
    ? props.textColor
    : {
        colorStops: [Colors.NEUTRAL.WHITE, 'var(--main-color-100)'],
        toDirection: '180deg',
      };

  return {
    lineColor: GlobalUtils.CSS.getBackgroundColor(lineColor),
    textColor: GlobalUtils.CSS.getBackgroundColor(textColor),
  };
});
</script>

<style scoped lang="scss">
.section-title {
  --line-color: v-bind('styleVariables.lineColor');
  --text-color: v-bind('styleVariables.textColor');
}
</style>

<style scoped lang="scss" src="./SectionTitle.scss" />
